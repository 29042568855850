import React from 'react';
import { Link } from 'react-router-dom';


function Footer() {
  return (
    <div className="full_color pt-5 pb-4">
      <div className="container mt-3">
        <div className="row">
          <div className="col-lg-4 ">
            <div className='footer_logo'><img src="./img/logo.png" alt="" className='w-100 img-fluid' /></div>
          </div>
          <div className="col-md-4">
            <div className="text-light text-center fs-5 footer_link">
              <ul>
                <li>  <Link to='/Comingsoon'>Home</Link></li>
                <li> <Link to='/Comingsoon'>Block</Link></li>
                <li><Link to='/Comingsoon'>Dex</Link></li>
                <li> <Link to='/Comingsoon'>Defi</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="text-light text-center fs-5 footer_link">
              <ul>
                <li>  <Link to='/WhitePaper'>WhitePaper</Link></li>
                <li> <Link to='/Tokenomics'>Tokenomics</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
