import React from 'react';
import Header from './Header';
import Footer from './Footer';

function Roadmap() {

    const steps = [
        { title: 'Step 1', description: 'Event description for Step 1' },
        { title: 'Step 2', description: 'Event description for Step 2' },
        { title: 'Step 3', description: 'Event description for Step 3' },
        // Add more steps as needed
    ];
    return (<>
        <Header />
        <div className="historical-steps rodemap_bg mt-5 pt-5 pb-5 mb-5">
            <div class="container">
                <div class="row text-center justify-content-center mb-5  mt-5 ">
                    <div class="col-xl-6 col-lg-8">
                        <h2 class="font-weight-bold gradient"> RodeMap</h2>
                        <p class="text-white ">We’re very proud of the path we’ve taken. Explore the history that made us the company we are today.</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col  pb-5">
                        <div class="timeline-steps aos-init aos-animate" data-aos="fade-up">
                            <div class="timeline-step">
                                <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                                    <div class="inner-circle"></div>
                                    <h3 class="mt-3 mb-1">Phase1</h3>
                                    <p class="h6 mt-3 mb-1">Q1-2 2023</p>
                                    <p class="h6  mb-0 mb-lg-0">Blockchain Platform setup Whitepaper Creation: Explorer Development:
                                        Layer 1 Establishment (Binance Smart Chain):
                                        Tokenomics Study:
                                        Node and Master Node Setup:
                                        Testnet Launch:
                                    </p>
                                </div>
                            </div>
                            <div class="timeline-step">
                                <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2004">
                                    <div class="inner-circle"></div>
                                    <h3 class="mt-3 mb-1">Phase2</h3>
                                    <p class="h6 mt-3 mb-1">Q3 2023</p>
                                    <p class="h6  mb-0 mb-lg-0">Defi (Decentralized Finance):
                                        Dex (Decentralized Exchange):
                                        Layer 2 (Frontend) Development:
                                    </p>
                                </div>
                            </div>
                            <div class="timeline-step">
                                <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2005">
                                    <div class="inner-circle"></div>
                                    <h3 class="mt-3 mb-1">Phase3</h3>
                                    <p class="h6 mt-3 mb-1">Q4 2023</p>
                                    <p class="h6  mb-0 mb-lg-0">Blockchain Payment Protocol:
                                        Blockchain-Based Stock Trading Platform Development:
                                        Coordinate Exchange Listing:  (with a target final listing by December).
                                        Layer 2 Backend (Ethereum) Integration:</p>
                                </div>
                            </div>
                            <div class="timeline-step">
                                <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2010">
                                    <div class="inner-circle"></div>
                                    <h3 class="mt-3 mb-1">Phase4</h3>
                                    <p class="h6 mt-3 mb-1">Q1 2024</p>
                                    <p class="h6  mb-0 mb-lg-0">Launch of Blockchain-Based Stock Trading Platform:
                                        API Marketplace Launch</p>
                                </div>
                            </div>
                            <div class="timeline-step mb-0">
                                <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2020">
                                    <div class="inner-circle"></div>
                                    <h3 class="mt-3 mb-1">Phase5</h3>
                                    <p class="h6 mt-3 mb-1">Q2 2024</p>
                                    <p class="h6  mb-0 mb-lg-0">Blockchain Store:
                                        Mainnet Launch:
                                        Continual Roadmap Updates and Engagement</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
    );
}

export default Roadmap;
