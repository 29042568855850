import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Tokenomics() {
    return (
        <>
            <Header />
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-10 col-12 mx-auto mt-5 text-center'>
                        <h1 className='mt-5 pt-5 gradient' >TOTAL SUPPLY 61 MILLION</h1>
                        <img src='./img/Tokenomics.png' className='w-100' />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Tokenomics