import React from "react";
import { Link } from "react-router-dom";

function Trusted() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="text-center pt-5 ">
            <img src="./img/haha.png" className="pb-4" />
            <h1 className="gradient"> Trusted by world class operators, </h1>
            <h1 className="gradient pb-5 mb-4">investors and institutions</h1>
          </div>

          <div class="text-center ">
            <div class="row">
              <div class="col-lg-3 col-md-3">
                <img src="./img/3.png" className="w-75 pt-3" />
              </div>
              <div class="col-lg-3 col-md-3">
                <img src="./img/2.png" className="w-75 pt-3" />
              </div>
              <div class="col-lg-3 col-md-3">
                <img src="./img/1.png" className="w-75 pt-3" />
              </div>
              <div class="col-lg-3 col-md-3">
                <img src="./img/4.png" className="w-75 pt-3" />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-md-3">
                <img src="./img/8.png" className="w-75 pt-3" />
              </div>
              <div class="col-lg-3 col-md-3">
                <img src="./img/5.png" className="w-75 pt-3" />
              </div>
              <div class="col-lg-3 col-md-3 pt-4">
                <img src="./img/10.png" className="w-75 pt-3" />
              </div>
              <div class="col-lg-3 col-md-3">
                <img src="./img/11.png" className="w-75 pt-3" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-3 col-md-3">
                <img src="./img/6.png" className="w-75 pt-3" />
              </div>
              <div class="col-lg-3 col-md-3">
                <img src="./img/3.png" className="w-75 pt-3" />
              </div>
              <div class="col-lg-3 col-md-3">
                <img src="./img/7.png" className="w-75 pt-3" />
              </div>
              <div class="col-lg-3 col-md-3">
                <img src="./img/9.png" className="w-75 pt-3" />
              </div>
            </div>
          </div>
          <div className="row pt-5 pb-5 mt-5 ">
            <div className="col-md-6 ">
              <img src="./img/design-3.png" className="des_ign" />
            </div>
            <div className="col-md-6">
              <div className="Power_ful md:mt-0 mt-4 md:text-start ">
                <h1 className="gradient">
                  Powerful
                  <br />
                  Infrastructure
                </h1>
              </div>
              <div className="row  pt-4 pb-3 text Power_ful">
                <div className="col-md-6 col-lg-6 col-12">
                  <h4 className="text-white">Unparalleled speed</h4>
                </div>
                <div className="col-md-6 col-lg-6 col-12 pr_a">
                  <p className="text-white">
                    The fastest blockchain in the industry, unlocked by Twin
                    Turbo Consensus. All with low transaction costs and industry
                    leading throughput.
                  </p>
                  <p className="text-white">
                    Modern applications require modern performance and
                    scalability.
                  </p>
                </div>
              </div>
              <div className="row d-flex pt-4 pb-3 text Power_ful">
                <div className="col-md-6 col-lg-6 col-12">
                  <h4 className="text-white">Security-First</h4>
                </div>
                <div className="col-md-6 col-lg-6 col-12 pr_a">
                  <p className="text-white">
                    Secured by some of the largest institutions in the world,
                    Blackbit is built from the ground up to prioritize security above
                    all else.
                  </p>
                </div>
              </div>
              <div className="row d-flex pt-4 pb-3 text ">
                <div className="col-md-6 col-lg-6 col-12">
                  <h4 className="text-white">User centric</h4>
                </div>
                <div className="col-md-6 col-lg-6 col-12 pr_a">
                  <p className="text-white">
                    With a deep commitment to fairness, Blackbit is fully open source
                    and offers native frontrunning protection, seamless
                    interoperability, and multiple levels of transaction
                    bundling.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="back_color">
        <div className="container">
          <h1 className="gradient  text-center pt-5  ">
            Comparing to Other Blockchains
          </h1>
          <div className="row  pt-5 pb-5">
            <div className="col-lg-3 text md:mt-0 mt-4">
              <div className="border pt-2 rounded-3">
                <div className="">
                  <div className="border-bottom">
                    <h5 className=" pb-1 text-white p-4">Bitcoin</h5>
                  </div>
                  <div className=" text-white p-4">
                    <h6 className="small text-lite">
                      TPS
                    </h6>
                    <h6 className="pb-3 fs-5">7-10</h6>
                    <h6 className="small text-lite">Transactional Finality</h6>
                    <h6 className="pb-3 fs-5">10 mins</h6>
                    <h6 className="small text-lite">Frontrunning prevention</h6>
                    <h6 className="pb-3 fs-5">None</h6>
                    <h6 className="small text-lite">Processing style</h6>
                    <h6 className="pb-3 fs-5">Sequential</h6>
                    <h6 className="small text-lite">Sybil Protection</h6>
                    <h6 className="pb-5">Proof of Work</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 text md:mt-0 mt-4">
              <div className="border pt-2 rounded-3">
                <div className="">
                  <div className="border-bottom">
                    <h5 className=" pb-1 text-white p-4">Ethereum</h5>
                  </div>
                  <div className=" text-white p-4">
                    <h6 className="small text-lite">
                      TPS
                    </h6>
                    <h6 className="pb-3 fs-5">24000</h6>
                    <h6 className="small text-lite">Transactional Finality</h6>
                    <h6 className="pb-3 fs-5">5-20 seconds</h6>
                    <h6 className="small text-lite">Frontrunning prevention</h6>
                    <h6 className="pb-3 fs-5">None</h6>
                    <h6 className="small text-lite">Processing style</h6>
                    <h6 className="pb-3 fs-5">Sequential</h6>
                    <h6 className="small text-lite">Sybil Protection</h6>
                    <h6 className="pb-5">Proof of Work</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 text md:mt-0 mt-4">
              <div className="border pt-2 rounded-3">
                <div className="">
                  <div className="border-bottom">
                    <h5 className=" pb-1 text-white p-4">Solana</h5>
                  </div>
                  <div className=" text-white p-4">
                    <h6 className="small text-lite">
                      TPS
                    </h6>
                    <h6 className="pb-3 fs-5">15-30</h6>
                    <h6 className="small text-lite">Transactional Finality</h6>
                    <h6 className="pb-3 fs-5">15 seconds-5 mins</h6>
                    <h6 className="small text-lite">Frontrunning prevention</h6>
                    <h6 className="pb-3 fs-5">None</h6>
                    <h6 className="small text-lite">Processing style</h6>
                    <h6 className="pb-3 fs-5">Sequential</h6>
                    <h6 className="small text-lite">Sybil Protection</h6>
                    <h6 className="pb-5">Proof of Work</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 text md:mt-0 mt-4">
              <div className="border pt-2 rounded-3">
                <div className="">
                  <div className="border-bottom">
                    <h5 className=" pb-1 text-white p-4">Blackbit </h5>
                  </div>
                  <div className=" text-white p-4">
                    <h6 className="small text-lite">
                      TPS
                    </h6>
                    <h6 className="pb-3 fs-5">1 lakh</h6>
                    <h6 className="small text-lite">Transactional Finality</h6>
                    <h6 className="pb-3 fs-5">5-15 seconds</h6>
                    <h6 className="small text-lite">Frontrunning prevention</h6>
                    <h6 className="pb-3 fs-5">None</h6>
                    <h6 className="small text-lite">Processing style</h6>
                    <h6 className="pb-3 fs-5">Sequential</h6>
                    <h6 className="small text-lite">Sybil Protection</h6>
                    <h6 className="">Proof of Work</h6>
                  </div>
                  <button type="button" class="btn text-white  btn-lg  STA_RT">
                    <Link className="text_white" to='/Whitepaper'> Start Building</Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pt-5 pb-5  mt-5  ">
          <div className="col-lg-12">
            <h1 className="gradient text-center mb-4" >
              Who s Building on Blackbit?
            </h1>
          </div>
          <div className="col-lg-12 col-md-12">
            <div className="row d-flex justify-content-center">
              <div className="col-md-8 d-flex justify-content-center">
                <div className="row justify-content-center">
                  <div className="col-lg-2 col-md-2 text-center ">
                    <img
                      src="./img/21.png"
                      className="rounded-pill w-50 text-center mt-5"
                    />
                    <h6 className="text text-white p-3">
                      Axelar <br /> Infrastructure
                    </h6>
                  </div>
                  <div className="col-lg-2 col-md-2 text-center ">
                    <img
                      src="./img/22.png"
                      className="rounded-pill w-50 text-center mt-5"
                    />
                    <h6 className="text  text-white pt-3">
                      Pyth <br /> Oracle
                    </h6>
                  </div>
                  <div className="col-lg-2 col-md-2 text-center ">
                    <img
                      src="./img/23.png"
                      className="rounded-pill w-50 text-center mt-5"
                    />
                    <h6 className="text  text-white pt-3">
                      Sushi <br /> DeFi
                    </h6>
                  </div>
                  <div className="col-lg-2 col-md-2 text-center ">
                    <img
                      src="./img/24.png"
                      className="rounded-pill w-50 text-center mt-5"
                    />
                    <h6 className="text text-white  pt-3">
                      Kargo  Prediction<br /> Markets
                    </h6>
                  </div>
                  <div className="col-lg-2 col-md-2 text-center ">
                    <img
                      src="./img/25.png"
                      className="rounded-pill w-50 text-center mt-5"
                    />
                    <h6 className="text text-white  pt-3">
                      Nitro <br /> Infrastructure
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3 ">
            <div className="col-lg-12 text-center">
              <button
                type="button"
                class="btn text-white  btn-lg mt-5 mb-4 STA_RT w-10"
              >
               <Link className="text_white" to="/WhitePaper">Start Building</Link> 
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-5  ">
          <div className="col-md-6 col-lg-6 col-12 text-center">
            <img src="./img/27.png" className="Bloc_kchain pt-5 mt-5 mx-auto" />
          </div>
          <div className="col-md-6 col-lg-6 col-12">
            <h1 className="gradient pb-5 m-3">
              A New Blockchain <br /> Design Space
            </h1>
       
              <div className=" bg-transparent border-0 border-bottom-1 text-white ">
                <h4> Highly Secure</h4></div>

              <div className="bg-transparent border-0 border-bottom-1 text-white mt-4">
                <h4>Massively Scalable</h4>
               
              </div>

              <div className=" bg-transparent border-0 border-bottom-1 text-white  mt-4">
                <h4 > Engineered to Evolve </h4>
                
              </div>

              <div className=" bg-transparent border-0 border-bottom-1 text-white mt-4">
                <h4>The Fastest Blockchain </h4> </div>

              <div className=" bg-transparent border-0 border-bottom-1  text-white mt-4">
                <h4> Brand New Applications</h4> </div>
          
          </div>
        </div>
      </div>

    </div>
  );
}

export default Trusted;
