import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Comingsoon() {
    return (
        <>
            <Header />
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-10 col-12 mx-auto mt-5 text-center w-100 ptb-100'>
                        <h1 className='mt-5 pt-5 gradient  font_size_80' >Coming Soon...</h1>
                      
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Comingsoon