import React, { useState } from 'react';
import { Link } from 'react-router-dom'
function Banners({ initWeb3 }) {
    console.log("initWeb3------------------", initWeb3)
    const [accounts, setAccounts] = useState([]);
    const [isMetaMaskConnected, setIsMetaMaskConnected] = useState(false);
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(accounts);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1500); // Reset the copied state after 1.5 seconds
    };
    const connectToMetaMask = async () => {
        try {
            const web3 = await initWeb3()
            const accounts = await web3.eth.requestAccounts();
            let walletAddress = accounts[0]
            setAccounts(walletAddress);
            setIsMetaMaskConnected(true);
            console.log('Connected to MetaMask:', accounts);

        } catch (error) {
            console.error(error);
        }
    };

    console.log("accounts", accounts)

    const buyTokens = async () => {

    };

    return (
        <div className="container mt_80">
            <div className="row">
                <div className="col-md-7 col-lg-7 col-12 py-5">
                    <h2 className='gradient pt-5'>
                        Global Blackbit, first multilayer token in the world

                    </h2>
                    {/* <h1 className='text-white gradient'>
                        “All-in-One” AI Ecosystem
                    </h1> */}
                    <h5 className='text-white'>

                        Specifically built for institutional investors, retailers.

                    </h5>
                    <div className="row">
                        <div className="col-md-10 col-lg-10 col-12 py-5">
                            <p className="text-white">
                                Multilayer platform whih includes defi,Dex, payment interface,stock exchange in a single application.Instituional investors, retailers get an edge with this platform
                            </p>
                        </div>
                        <div className="col-md-2 col-lg-2">

                        </div>
                    </div>
                </div>
                <div className="col-md-5 col-lg-5 col-12 py-5">
                    <div className='walleetpart'>
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-7 p-3  ">
                                Presale Stage 7
                            </div>
                            <div className="col-md-6 col-lg-6 col-5 p-4 text-end">
                                <i class="fa-solid fa-circle bg-secondary rounded-3"></i> open
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-12 p-4 text-center">
                                <h6>Until next price $0.11 (+11.11%)</h6>
                                <div className="progress" style={{ height: '10px' }}>
                                    <div className="progress-bar" style={{ width: '90%', }}></div>
                                </div>
                                <h6> $4,558,947.55/$4,582,551.25 </h6>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-6 p-4">
                                <p className='pb-0 mb-0'>Presale Price</p>
                                <h4>$0.1</h4>
                            </div>
                            <div className="col-md-6 col-lg-6 col-6 p-4 text-end">
                                <p className='pb-0 mb-0'>Listing Price</p>
                                <h4>$0.12</h4>
                            </div>
                            <div className='col-12 px-4 my-2'>
                                <div className='boder1'></div>
                            </div>
                            <div className='col-12 p-4 relative text-center ypred'>
                                <div className='boder2'></div>
                                <h6>1 BKB = 0.29 $</h6>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-12 ">
                                <div class="d-flex justify-content-evenly button_pay">
                                    <button type="button" class="btn btn-success m-1">
                                        <i class="fa-brands fa-ethereum"></i> ETH
                                    </button>
                                    <button type="button" class="btn btn-success m-1">
                                        <i class="fa-regular fa-credit-card"></i> MATIC
                                    </button>
                                    <button type="button" class="btn btn-success m-1">
                                        <i class="fa-regular fa-credit-card"></i> CARD
                                    </button>
                                    <button type="button" class="btn btn-success m-1">
                                        More  <i class="fa-regular fa-credit-card"></i>
                                    </button>
                                </div>
                            </div>

                            <div className="col-md-12 col-lg-12 col-12 ">
                                <div className='row'>
                                    <div className='col-12 p-3'>
                                        <div className="input-group input-groups">
                                            <div className="d-flex">
                                                <span>$</span> <i class="fa-brands fa-ethereum"></i>
                                            </div>

                                            <input type="text" className="form-control text-center" aria-label="Text input with 2 dropdown buttons" />
                                            <div className="d-flex">
                                                <i class="fa-brands fa-ethereum"></i> <span>$</span>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='col-12 px-5 p-3'>
                                        {!isMetaMaskConnected ? (
                                            <button class="btn btn-primary w-100 m" type="button" onClick={connectToMetaMask}>  <i class="fa-brands fa-ethereum"></i> Connect Wallet</button>
                                        ) : (
                                            <button class="btn btn-primary w-100 m" type="button" onClick={buyTokens}>  <i class="fa-brands fa-ethereum"></i> Buy Now</button>
                                        )}
                                    </div>
                                    <div className='col-12 px-5 p-3'>
                                        <div className="borders  rounded-3 p-3  pb-0 ">
                                            <div className="">
                                                <p className='text-center address-container mx-auto'>{accounts}</p>
                                                <p className='text-center d-flex justify-content-center '>
                                                    <div className='imag_mata_mack'>
                                                        <a onClick={copyToClipboard}>
                                                            <i className='fas fa-copy'></i>
                                                        </a>
                                                        {copied && <span className="ms-2">Copied!</span>}
                                                    </div>
                                                    <div className='imag_mata_mack'>
                                                        <Link to={"/"}><img src='../img/MetaMask_Foxpng.png' className='w-100' alt='MetaMask Logo'></img></Link>
                                                    </div>
                                                </p>
                                            </div>



                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Banners